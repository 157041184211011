import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import { colors } from '../components/theme'
import MetaHeader from '../components/MetaHeader'
import PageWrapper from '../components/PageWrapper'
import Video from '../components/Video'
import ArticleHeader from '../components/common/ArticleHeader'
import NewsletterForm from '../components/NewsletterForm'

const Section = styled.section`
  margin-top: 0;
`
const NewsletterSection = styled.div`
  background: ${colors.primaryLight};
  padding: 2rem 0;
  margin: 2rem 0 -2rem;
`
const BlogPost = ({ data }) => {
  const {
    heroImage,
    title,
    slug,
    postedAt,
    body,
    youtubeId,
    summary,
    keywords,
  } = data.contentfulPost

  return (
    <Layout>
      <MetaHeader
        title={title}
        image={heroImage?.file?.url}
        siteUrl={`https://femalefunders.com/blog/${slug}`}
        description={summary}
        keywords={keywords}
      />
      <PageWrapper>
        <ArticleHeader heroImage={heroImage} date={postedAt} title={title} />
        <Section>
          {youtubeId && <Video id={youtubeId} />}
          <div
            dangerouslySetInnerHTML={{
              __html: `${body.childContentfulRichText.html}`,
            }}
          />
        </Section>
      </PageWrapper>

      <NewsletterSection>
        <PageWrapper>
          <NewsletterForm />
        </PageWrapper>
      </NewsletterSection>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      heroImage {
        id
        fluid {
          src
        }
        file {
          url
        }
      }
      title
      slug
      postedAt(formatString: "MMMM Do, YYYY")
      youtubeId
      body {
        childContentfulRichText {
          html
        }
      }
      category {
        id
      }
      summary
      keywords
    }
  }
`
