import React from 'react'
import styled from 'styled-components'
import YouTube from 'react-youtube'

const Video = ({ className, id }) => (
  <div className={className}>
    <YouTube
      videoId={id}
      opts={{
        height: '100%',
        width: '100%',
        playerVars: {
          autoplay: 0,
          controls: 0,
          cc_load_policy: 0,
          fs: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
        },
      }}
    />
  </div>
)

export default styled(Video)`
  position: relative;
  overflow: hidden;
  margin: 0 auto 1rem;

  // YouTube component can't be extended by styled
  > div {
    padding-bottom: 56.25%;
    height: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
